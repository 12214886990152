#preview .step {
  margin-bottom: 10px;
  padding: 0.75rem;
  border: 1px solid #f4f7fa;
  border-radius: 4px;
  background-color: #f4f7fa;
}

#preview .step .step__label {
  font-weight: bold;
}

#preview .step .step__label:before {
  display: inline-block;
  margin-right: 15px;
  padding: 8px;
  color: #fff;
  background-color: #0060db;
  border-radius: 50%;
  content: attr(data-label);
}

#iframe {
  width: 0;
  height: 0;
  opacity: 0;
}